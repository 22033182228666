<template>
    <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <v-date-picker v-model="date" />

                </div>

                <div class="modal-footer">
                    <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="emitDate" type="button" class="btn btn-primary" data-bs-dismiss="modal"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import UtilService from '@/service/UtilService.js';
    export default {

        props: {
            inputId: String,
            title: String,
        },

        data() {
            return {
                date: new Date(),
            }
        },

        methods: {
            emitDate() {
                console.log("EMIT");
                let utcDate = UtilService.dateToUTC(new Date(this.date));

                this.$emit('confirm', utcDate.toISOString().slice(0,10))        
            }
        }

    }
</script>

<style scoped>

    .modal-body{
        margin: 0 auto;
    }
</style>