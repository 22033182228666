<template>
    <div>
        <modal-assign-trip ref="asignTrip" :employees="employees" modalId="assignTripModal" title="Dodeljivanje vozila" :date="selected_date" @submited="hideModal()" />
        <modal-date-picker inputId="datePickerModal" title="Izaberite datum voznji" message="Test" @confirm="loadSuggestedTripsByDate" />


        <div class="table-title">
            <div class="container-xl">
                <div class="row">
                    <div class="btn-group col-md-4" role="group" aria-label="Basic outlined example">
                        <router-link :to="{ name: 'EmployeesAssignment', params: {'initalSelectedDate': selected_date }}" class="btn btn-primary">Na radni nalog</router-link>
                        <router-link :to="{ name: 'EmployeesAssignmentTrip'}" class="btn btn-primary active">Dodeli vozila</router-link>
                        <router-link :to="{ name: 'EmployeesAssignmentAndTrip', params: {'initalSelectedDate': selected_date }}" class="btn btn-primary">Dnevni prikaz</router-link>


                    </div>

                    <div class="d-flex flex-wrap justify-content-end col-md-8">
                        <div>
                            <div class="form-floating">
                                <input type="date" class="form-control" id="woEndDate" v-model="selected_date" placeholder="Za datum" required @change="changedDateLoad">
                                <label for="woEndDate">Za datum</label>
                                <div class="invalid-feedback">
                                    Unesite datum
                                </div>
                            </div>
                        </div>
                        <template v-if="user.role == 'ADMIN'">
                            <div v-if="showTimeInput" class="d-flex me-3">
                                <div class="form-floating">
                                    <input type="time" class="form-control" id="woEndDate" v-model="selected_time" placeholder="Vreme" required @change="changedDateLoad">
                                    <label for="woEndDate">Vreme</label>
                                    <div class="invalid-feedback">
                                        Unesite vreme
                                    </div>
                                </div>
                                <button type="button" class="btn time-close" v-on:click="resetTime">✕</button>
                            </div>
                            
                            <div v-else class="me-3">
                                <button type="button" class="btn white-btn" v-on:click="showTime">Vreme</button>
                            </div>
                        </template>
                        <div class="d-flex">
                            <div>
                                <div class="form-floating">
                                    <input type="number" min="0" max="30" class="form-control" id="daysBefore" placeholder="broj dana pre" v-model.number="daysBefore" @change="changeSelectedDays">
                                    <label for="daysBefore">Pre</label>
                                </div>
                            </div>
                            <div>
                                <div class="form-floating">
                                    <input type="number" min="0" max="30" class="form-control" id="daysAfter" placeholder="broj dana posle" v-model.number="daysAfter" @change="changeSelectedDays">
                                    <label for="daysAfter">Posle</label>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="container-xl middle-menu mt-5 mb-5">
            <div class="row">
                <div class="col-md-8">
                    <h3>Dodelite vozila zaposlenima da nastavite</h3>
                    <p>Izabrali ste <strong>{{totalVehicles}}</strong> vozila na <strong>{{totalPassengers}}</strong> putnika</p>
                    <button :disabled="totalPassengers == 0" @click="showAssignModal" class="btn btn-primary">Dodeli vozače</button>
                </div>
                <div class="col-md-4">

                    <div class="form-floating">
                        <input type="date" class="form-control" id="woEndDate" v-model="selected_date" placeholder="Za datum" required @change="loadTimelineWorkorders">
                        <label for="woEndDate">Za datum</label>
                        <div class="invalid-feedback">
                            Unesite datum
                        </div>
                    </div>    
                </div>
            </div>
        </div> -->


        <div class="col-lg-12 control-section" v-if="employees">
            <div class="e-mobile-content">

                <ejs-grid
                    v-if="gridReady"
                    ref="empAssignmentTrip"
                    :dataSource="employeesWorkers" 
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :allowGrouping='true'
                    :groupSettings='groupOptions'
                    :filterSettings='filterSettings'
                    :actionComplete='actionCompleteEvent'
                    :resizeStop='resizeEvent'
                    :created="createdTable"
                    :allowSelection='false'
                    :allowResizing='true'
                    :allowReordering='true'
                    gridLines='Both'
                    :queryCellInfo='customiseCell'
                    class="trip-assign-table"
                    :enablePersistence='false'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    :recordDoubleClick="recordDoubleClick"
                    :actionFailure='actionFailure'
                    :dataBound='dataBound'
                    >
                    <e-columns>
                        <e-column field=''              index="3" order='4'    headerText='#' :template="rowIndexTemplate" :width='widthStates[0]' textAlign='Right' :visible='visibleStates[0]' ></e-column>
                        <e-column field='id'                headerText='ID'         :width='widthStates[1]' textAlign='Right' :isPrimaryKey='true' :visible='visibleStates[1]' ></e-column>
                        <e-column field='user.username'     headerText='Korisničko ime' :width='widthStates[2]' textAlign='Left' :visible='visibleStates[2]' :template="usernameTemplate"></e-column>
                        <e-column field='user.last_name'    headerText='Prezime'    :width='widthStates[3]' :visible='visibleStates[3]' ></e-column>
                        <e-column field='user.first_name'   headerText='Ime'        :width='widthStates[4]' :visible='visibleStates[4]' :customAttributes="infoAttributes" ></e-column>
                        
                        <e-column field='transport_type' :valueAccessor='transportTypeAccessor' headerText='Tip prevoza' :width='widthStates[5]'        :visible='visibleStates[5]' :customAttributes="infoAttributes" ></e-column>
                        <e-column field='user.role' :valueAccessor='roleAccessor' headerText='Uloga' :width='widthStates[6]'  :visible='visibleStates[6]' :customAttributes="infoAttributes" ></e-column>


                        <e-column v-for="time, timeId in allTimes" :key="timeId + selected_time" :field='"timeline." + timeId + ".display"' :headerText='time'  width='177' 
                                :headerTemplate='dateTimeHeaderTemplate' 
                                :visible='visibleStates[7+timeId]' :customAttributes="{class: 'time-col-' + timeId}" ></e-column>



                        <e-column v-for="time, timeId in tripsTimes" :key="timeId + selected_time" :field='"trip_timeline."+ timeId +".display"' :headerText='time'  width='177' 
                                :valueAccessor="tripTimelineAccessor" :headerValueAccessor="tripDateAccessor"
                                :visible='true' :customAttributes="{class: 'time-col-' + timeId}"
                                :template="slectedTripsId===timeId?vehicleChoice(timeId):null"
                                ></e-column>

                    </e-columns>
                </ejs-grid>
            </div>


        </div>

        <div class="vehicle-form-footer">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <h5 class="mt-2">Izabrali ste <strong>{{totalVehicles}}</strong> vozila na <strong>{{totalPassengers}}</strong> putnika</h5>
                    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">

                        <button :disabled="totalPassengers == 0 && !edited" @click="showAssignModal" class="btn btn-primary">
                            <template>Sačuvaj prevoz</template>
                        </button>


                        <div class="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Opcije
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <li><a class="dropdown-item" @click="clearVehiclesBtn">Obriši izabrana vozila</a></li>
                            <li><a class="dropdown-item" @click="suggestVehiclesBtn">Predloži vozila</a></li>
                            <li><a class="dropdown-item" @click="suggestVehiclesByDataBtn">Prekopiraj sa izabranog dana</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { Modal } from 'bootstrap';

    import EmployeesService from '@/service/EmployeesService.js';
    import TransportService from '@/service/TransportService.js';
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex';
    import Vue from 'vue';

    import { Sort, Page, ColumnMenu, Filter, Group, Reorder, Resize, Freeze, Toolbar, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";
    import DateTimeEmpAssignment from '@/components/grid-templates/DateTimeEmpAssignment.vue';
    import VehicleChoice from '@/components/grid-templates/VehicleChoice.vue';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import ModalAssignTrip from '@/components/modals/assignment/ModalAssignTrip.vue';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';
    import WorkOrderService from '@/service/WorkOrderService.js';
    import ModalDatePicker from '@/components/modals/utils/ModalDatePicker.vue';


    Vue.prototype.$eventHub = new Vue(); 


    export default {

        components: { ModalAssignTrip, ModalDatePicker },

        setup: {
            UtilService
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Reorder, Resize, Freeze, Toolbar, PdfExport, ExcelExport ]
        },

        data: function() {
            return {

                gridReady: false,
                employees: [],
                selectedEmployee: null,
                preWorkOrders: {},

                allTimes: [],
                pwoSchedulesMap: {},
                currentEmployeeWO: {},

                currentWorkOrder: {
                    workOrderId: null,
                    date: null
                },

                showTimeInput: false,
                selected_date: UtilService.formatDatePattern(UtilService.addDate(UtilService.getMoment(), 1, 'day')),
                selected_time: "00:00",
                daysBefore: 1,
                daysAfter: 1,

                modalConfirm: null,
                scheduleData: {},
                absences: null,
                pwoTimeline: null,


                groupOptions: { showGroupedColumn: true, columns: [] },
                filterSettings: { type: "Excel" },
                
                visibleStates: [true, false, false, true, true, true, false, true],
                widthStates: [60, 120, 120, 130, 130, 150, 150, 377, 177, 177, 177, 177, 177,],
                orderStates: [],
                groupState: [],

                infoAttributes: {class: 'col-info-side'},

                nonWorkingDays: [],
                employeesDayStatus: {},


                userTripsMap: {},
                tripsTimes: [],

                suggestedTrips: [],

                edited: false,

                currentSelectDate: null,
                currentSelectData: null,


                dateTimeHeaderTemplate() {
                    return {
                        template: DateTimeEmpAssignment
                    };
                },


                vehiclesCapacity: {},
                totalPassengers: 0,
                assignTripModal: null,
                datePickerModal: null,

                slectedTripIndex: undefined,

                vehicleChoice(val) {
                    return () => {
                        let self = this;
                        return {
                            template: {
                                parent: self,
                                extends: VehicleChoice,
                                propsData: {'selected_date': this.selected_date, 'timelineTripIndex': val, nonWorkingDays: this.nonWorkingDays}
                            }
                        }
                    }
                },

                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }

                },

                usernameTemplate: function() {
                    return {
                        template: UsernameTemplate
                    };
                },

            };
        },  
        computed: {
            ...mapState([
                'user',
                'allWorkOrders',
                'userSettings',
                'userCache',
                'userTrips',
                'transportVehicles',
            ]),

            leftBoundDate() {
                return UtilService.convertMoment(this.selected_date).add(-this.daysBefore, 'day');
            },

            rightBoundDate() {
                return UtilService.convertMoment(this.selected_date).add(this.daysAfter, 'day');
            },

            employeesWorkers() {
                let workers = []
                this.employees.forEach(employee => {
                    // if (employee.user.role == "ADMIN") return;
                    workers.push(employee)
                });

                return workers

            },

            todayTimeId() {
                return this.allTimes.indexOf(this.getDateTimeSeconds());
            },

            slectedTripsId() {
                return this.tripsTimes.indexOf(this.getDateTimeSeconds());
            },

            totalVehicles() {
                let vehicles = 0;
                Object.values(this.vehiclesCapacity).forEach(capacity => {
                    if (capacity > 0) vehicles++;
                });
                return vehicles;
            }

        },

        methods: {

            formatDate: UtilService.formatDate,
            translateRole: UtilService.translateRole,
            absenceTypeFormat: UtilService.absenceTypeFormat,
            translateTransportType: UtilService.translateTransportType,

            roleAccessor(field, data) {
                if (field == "key") return this.translateRole(data.key);

                return this.translateRole(data['user'].role);
            },

            transportTypeAccessor(field, data) {
                if (field == "key") return this.translateTransportType(data.key);

                return this.translateTransportType(data['transport_type']);
            },

            timelineAccessor(field, data) {
                if (field == "key") return data.key;

                let fieldTokens = field.split('.');
                if (!data.timeline) return "";

                let pwo = data.timeline[fieldTokens[1]];
                let role = pwo?.pwo_role==1?"(M) ":"";
                let name = pwo?.work_order_obj?.name?pwo?.work_order_obj?.name:""
                return role + name;
            },

            tripTimelineAccessor(field, data) {
                if (field == "key") return data.key;


                let fieldTokens = field.split('.');
                // if (!data.timeline) return "";

                let trip = data?.trip_timeline?.[fieldTokens[1]];
                if (!trip || !trip.vehicle_obj) return;

                let driver = trip?.driver === data.id ? "(V) " :"";
                return driver + trip?.vehicle_obj?.name;

                // let fieldTokens = field.split('.');
                // if (!data.timeline) return "";

                // let pwo = data.timeline[fieldTokens[1]];
                // let role = pwo?.pwo_role==1?"(M) ":"";
                // let name = pwo?.work_order_obj?.name?pwo?.work_order_obj?.name:""
                // return role + name;
            },

            tripDateAccessor(field, column) {
                return UtilService.formatDateAndOrTime(column.headerText);
            },



            customiseCell(args) {
                if (args.column.field.startsWith('timeline.') == true) {
                    let trimmed = args.column.field.slice(9);
                    let nextDot = trimmed.indexOf('.');
                    trimmed = trimmed.slice(0, nextDot);

                    if (args.data?.timeline == null) return;
                    let currentDay = args.data?.timeline[trimmed];
                    if (!currentDay) return;

                    if (currentDay.absence_type) {
                        args.cell.classList.add('absence-day');
                        if (currentDay.absence_type == 'VACATION') args.cell.classList.add('absence-vacation-day');
                        else if (currentDay.absence_type == 'SICKNESS') args.cell.classList.add('absence-sickness-day');
                        else if (currentDay.absence_type == 'RELIGIOUS') args.cell.classList.add('absence-religious-day');
                        else args.cell.classList.add('absence-other-day');
                    } else if (currentDay.non_working_day && !currentDay.force_working_day) {
                        args.cell.classList.add('non-working-day');
                    } else if (currentDay.work_order === null) {
                        args.cell.classList.add('no-work-order');
                    } else if (currentDay.working && currentDay.date <= this.todayDateTime && currentDay.appeared == undefined) {
                        args.cell.classList.add('not-appeared')
                    }


                    if (currentDay.edited) {
                        args.cell.classList.add('edited')
                    }

                    
                }

                // if (args.column.field.startsWith('trip_timeline.' + this.slectedTripsId) == true) {

                //     if (args.data?.timeline == null) return;


                //     // if (args.data?.timeline?.[0]) {
                //     //     if (!args.data?.timeline?.[0].working)
                //     //         args.cell.classList.add('non-working-day');
                //     // } 
                    
                //     // else if (currentDay.work_order === null) {
                //     //     args.cell.classList.add('no-work-order');
                //     // }

                //     // if (currentDay.nonWorkingDay) {
                //     //     args.cell.classList.add('non-working-day');
                //     // }

                    
                // }
            },

            changeSelectedDays() {
                this.slectedTripIndex = undefined;
                this.loadTimelineWorkorders();
            },

            resetTime() {
                this.hideTime();
                this.loadTimelineWorkorders();
            },

            hideTime() {
                this.showTimeInput = false;
                this.selected_time = "00:00";
            },

            showTime() {
                this.showTimeInput = true;
            },

            async changedDateLoad() {
                await this.loadTimelineWorkorders();

            },
            // loadActiveEmployees() {
            //     EmployeesService.getActiveEmployees()
            //     .then(response => { 
            //         this.employees = response.data; 
            //         this.employees = this.employees.filter(e => {
            //             if (!e.work_end_date)
            //                 return true;
                        
            //             return new Date(e.work_end_date) > Date.now();
            //         });
            //     })
            //     .catch((error) => { alert(error.message); });
            // },

            validateForm(form) {
                form.classList.add('was-validated');
                if (this.selectedEmployee == "" || this.currentWorkOrder.workOrderId == "" || this.currentWorkOrder.date == "")
                    return false;
    
                return form.checkValidity();
            },

            openWorkOrdersModal(employee) {
                this.selectedEmployee = JSON.parse(JSON.stringify(employee));
                EmployeesService.getPreWorkOrdersForEmployee({id: employee.id, date: 'all'})
                .then(response => { 
                    this.preWorkOrders = response.data; 
                    for (let el of this.preWorkOrders)
                        el.date = this.formatDate(el.date)
            
                    this.workOrdersModal = new Modal(document.getElementById('workOrdersModal'))
                    this.workOrdersModal.show();
                })
                .catch((error) => { alert(error.message); });
            },


            setVehicle(userId, vehicleId) {
                let employee = this.employees.find(x => x.id == userId);
                if (!employee) return;


                if (!employee.currentTripAssign.edited) {
                    this.$set(employee.currentTripAssign, "old_vehicle", employee.currentTripAssign.vehicle)
                }

                employee.currentTripAssign.vehicle = vehicleId;
                this.$set(employee.currentTripAssign, "edited", true)
                this.calculateCapacity();

                this.saveSelectedVehicles();
                this.enableEditMode();
            },


            calculateCapacity() {
                console.log("IDE CAPACITY");
                var props = Object.getOwnPropertyNames(this.vehiclesCapacity);
                for (var i = 0; i < props.length; i++) {
                    this.vehiclesCapacity[props[i]] = 0;
                }
                this.totalPassengers = 0

                this.employees.forEach(employee => {
                    if (!employee.currentTripAssign?.vehicle) return;
                    if (!this.vehiclesCapacity[employee.currentTripAssign.vehicle])  this.vehiclesCapacity[employee.currentTripAssign.vehicle] = 0;

                    this.vehiclesCapacity[employee.currentTripAssign.vehicle]++;
                    this.totalPassengers++;

                });

                this.vehiclesCapacity = Object.assign({},this.vehiclesCapacity);
                return this.vehiclesCapacity
            },


            async loadActiveWorkOrders() {
                await this.$store.dispatch('loadAllWorkOrders');
            },

            getDateTime() {
                return this.selected_date + 'T' + this.selected_time;
            },


            getDateTimeSeconds() {
                return this.selected_date + 'T' + this.selected_time + ':00';
            },


            async loadTimelineWorkorders() {
                // this.gridReady = false;

                await this.loadTrips();
                await this.loadPWO();
                this.loadCachedSelectedVehicles(this.employees);



            },

            // autoSelect() {
            //     if (this.selectedTripsId === -1){
            //         let tripTime = this.tripsTimes.find(x => x.slice(0,10) === this.selected_date)
                    
            //         this.changeDate(tripTime)
            //     }
            //     if (!this.tripsTimes) return;

            //     const index = this.tripsTimes.length;
            //     if (index > 0) this.selectTripByIndex(index - 1);
            //     else {
            //         this.slectedTripIndex = null;
            //         this.loadSuggestedTrips();
            //         return
            //     }
            // },

            getSlectedTripsId() {
                return this.tripsTimes.indexOf(this.getDateTimeSeconds());
            },

            getUndefinedSchedule() {
                return {work_order:undefined, work_order_obj: {name:"/"}};
            },

            async loadPWO() {
                let data = {
                    'start_date': this.selected_date,
                    'end_date': this.selected_date
                }

                await WorkOrderService.getPWOTimeline(data).then(async (result)=>{

                    this.calculateAllTimes(result.data);
                    this.timelineKeyValuesToIndex(result.data)
                    this.calcPWODisplayValue(result.data);

                    this.calcCurrentAssign(result.data);

                    this.employees.forEach(employee => {
                        let timeline_em = result.data.find(x=> x.id === employee.id)?.timeline;
                        employee.timeline = timeline_em;
                    })

                    this.gridReady = true;

                    

                });


            },

            calcCurrentAssign(employees) {
                const self = this;
                let selectedTimeId = self.todayTimeId.toString();

                employees.forEach(employee => {

                    if (employee.timeline?.[selectedTimeId] == null) {
                        employee.timeline[selectedTimeId] = self.getUndefinedSchedule();
                    }
                    employee.currentAssign = employee.timeline[selectedTimeId];
                });
            },

            calcPWODisplayValue(employees) {
                const self = this;
                employees.forEach(employee => {

                    if (!employee.timeline) return;
                    Object.values(employee.timeline).forEach(timeline_obj => {
                        

                        self.calcDeisplayValueForTimelineData(timeline_obj);

                    });

                });
            },

            isTimelineObjWorking(timeline_obj) {
                if (timeline_obj.absence_type) return false
                if (timeline_obj.non_working_day && !timeline_obj.force_working_day) return false
                if (timeline_obj.work_order > 0) return true
                return false;
            },

            calcDeisplayValueForTimelineData(timeline_obj) {
                timeline_obj.working = this.isTimelineObjWorking(timeline_obj);
                timeline_obj.display = this.getPWODisplayValue(timeline_obj);
            },

            getPWODisplayValue(timeline_obj) {
                if (timeline_obj.absence_type) return "Na odsustvu"
                if (timeline_obj.non_working_day && !timeline_obj.force_working_day) return "Neradni dan"
                if (timeline_obj.work_order_obj) return timeline_obj.work_order_obj.name;
                return "Neradni dan";
            },

            calculateAllTimes(timeline) {
                let first_timeline = timeline[0]['timeline'];

                this.$nextTick(() => {
                    this.allTimes = []
                    this.$nextTick(() => {
                        this.allTimes = Object.keys(first_timeline);
                    })
                })
                this.allTimes = Object.keys(first_timeline)
            },

            timelineKeyValuesToIndex(employees) {
                employees.forEach(employee => {
                    let timeline = employee.timeline;
                    let newTimeline = {}
                    if (timeline != undefined) {
                        Object.values(timeline).forEach((timeline_data, index) => {
                            newTimeline[index] = timeline_data;
                        });
                    }
                    
                    employee.timeline = newTimeline;

                });
            },

            timelineTripKeyValuesToIndex(employees) {
                employees.forEach(employee => {
                    let trip_timeline = employee.trip_timeline;
                    let newTimeline = {}
                    if (trip_timeline != undefined) {
                        Object.values(trip_timeline).forEach((timeline_data, index) => {
                            newTimeline[index] = timeline_data;
                        });
                    }
                    
                    employee.trip_timeline = newTimeline;

                });
            },

            async loadTrips() {
                let data = {
                    'start_date': this.leftBoundDate.format("YYYY-MM-DD"),
                    'end_date': this.rightBoundDate.format("YYYY-MM-DD")
                }

                this.$store.dispatch('loadUserTrips', {'date': this.selected_date });

                const self = this;
                await TransportService.getUserTripsTimeline(data).then(async (result)=>{
                    this.calculateTripsTimes(result.data)
                    this.timelineTripKeyValuesToIndex(result.data)
                    this.calcTripDisplayValue(result.data);
                    this.calcSelectedTrips(result.data);

                    this.employees = result.data.filter(x=> {
                        if (x.work_end_date && x.work_end_date <= self.selected_date) return false;
                        return true;

                    })

                    this.calculateCapacity();

                    
                });

            },

            calcTripDisplayValue(employees) {
                const self = this;
                employees.forEach(employee => {

                    if (!employee.trip_timeline) return;
                    Object.values(employee.trip_timeline).forEach(trip_obj => {
                        
                        self.calcDeisplayValueForTripData(trip_obj);

                    });

                });

            },

            calcDeisplayValueForTripData(trip_obj) {
                trip_obj.display = this.getTripDisplayValue(trip_obj);
            },

            getTripDisplayValue(trip_obj) {
                if (trip_obj.vehicle == -1) return "";
                let vehicle = this.transportVehicles.find(x=> x.id == trip_obj.vehicle);
                trip_obj.vehicle_obj = vehicle;
                return vehicle?.name;
            },

            calcSelectedTrips(employees) {
                const self = this;
                employees.forEach(employee => {

                    // if (employee.trip_timeline?.[selectedTimeId] == null) {
                    //     employee.trip_timeline[selectedTimeId] = self.getUndefinedSchedule();
                    // }
                    employee.currentTripAssign = employee.trip_timeline[self.getSlectedTripsId()];
                });
            },

            calculateTripsTimes(timeline) {
                let first_timeline = timeline[0]['trip_timeline'];

                this.tripsTimes = Object.keys(first_timeline);

                this.$nextTick(() => {
                    this.tripsTimes = [];
                    this.$nextTick(() => {
                        this.tripsTimes = Object.keys(first_timeline);
                    })
                })
            },

            async load_data() {
                this.loadAllVehicles();
                await this.loadActiveWorkOrders();
                await this.loadTimelineWorkorders();
            },

            async loadAllVehicles() {
                await this.$store.dispatch('loadAllTransportVehicles', this.workOrderForm).catch((error) => {
                    console.log(error);
                    alert("Neuspešno učitavanje vozila")
                });
            },

            // dateHasTime(str) {
            //     return UtilService.convertMoment(str).format('HH:mm:ss') !== "00:00:00"
            // },

            formatDateAndOrTime(str) {
                let date = UtilService.convertMoment(str);
                if (date.format('HH:mm:ss') === "00:00:00") {
                    return UtilService.formatDate(date)
                } else {
                    return UtilService.formatDateTime(date)
                }

            },


            isDateToday(datetune) {
                let date = UtilService.convertMoment(datetune);
                return date.isSame(UtilService.getMoment(), 'day');
            },

            changeDate(datetime) {
                let date = UtilService.convertMoment(datetime);
                this.selected_date = date.format("YYYY-MM-DD")
                this.selected_time = date.format("HH:mm")
                if (this.selected_time != "00:00") this.showTime();
                else this.hideTime();
            },

            // async updatedHeaderEvent(event) {
            //     this.visibleStates[event.cell.colIndex] = event.cell.visible;
            // },

            resizeEvent(event) {
                this.saveColumnStatus(event.column)
                this.saveColumnStates();
            },

            actionCompleteEvent(event) {
                if (event.requestType == "columnstate") {
                    this.saveAllColumnsStatus(event.columns)
                } else if (event.requestType == "reorder") {
                    this.saveAllColumnsStatusOrderStatus()
                } else if (event.requestType == "grouping") {
                    this.groupingState(event.columnName);
                } else if (event.requestType == "ungrouping") {
                    this.unGroupingState(event.columnName);
                } else if (event.requestType == "refresh") {
                    this.fixGroupingLabel();
                }
            },
            
            saveColumnStatus(column) {
                this.visibleStates[column.index] = column.visible;
                this.widthStates[column.index] = column.width;
            },

            resetTableTimelineVisibility() {
                let columns = this.$refs.empAssignmentTrip?.getColumns()
                if (!columns) return

                columns.forEach(column => {
                    if (!column.field.startsWith("timeline")) return

                    column.visible = true;
                    this.visibleStates[column.index] = column.visible;

                });
            },

            fixGroupingLabel() {
                let groupTags = document.querySelector('.trip-assign-table .e-groupdroparea').children;
                groupTags.forEach(element => {
                    let column = element.firstChild.getAttribute('ej-mappingname');
                    let text = element.firstChild.firstChild.innerText;

                    let reg1 = text.match('\\d{4}-[01]\\d-[0-3]\\d');
                    let reg2 = text.match('\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d');
                    
                    if (reg2 != null) {
                        let rexp = new RegExp(reg2);
                        let formated
                        if (column.startsWith('tripTimeline'))
                            formated = "Vozilo u " + UtilService.formatTime(reg2[0])
                        else
                            formated = UtilService.formatDateTime(reg2[0])
                        element.firstChild.firstChild.innerText = text.replace(rexp, formated);
                    } else if (reg1 != null){
                        let rexp = new RegExp(reg1);
                        let formated = UtilService.formatDate(reg1[0])
                        element.firstChild.firstChild.innerText = text.replace(rexp, formated);
                        
                    }
                    
                });
                

                let groupsText = document.querySelectorAll('.trip-assign-table .e-table .e-groupcaption');
                groupsText.forEach(element => {
                    let text = element.innerText;

                    let reg1 = text.match('\\d{4}-[01]\\d-[0-3]\\d');
                    let reg2 = text.match('\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d');
                    if (reg2 != null) {
                        let rexp = new RegExp(reg2);
                        element.innerText = text.replace(rexp, "").slice(1);
                    } else if (reg1 != null){
                        let rexp = new RegExp(reg1);
                        element.innerText = text.replace(rexp, "").slice(1);
                    }

                });
            },

            groupingState(column) {
                this.groupOptions.columns.push(column)
                this.saveColumnStates();
                this.fixGroupingLabel(column);

            },

            unGroupingState(column) {
                let index = this.groupOptions.columns.indexOf(column);
                if (index !== -1) {
                    this.groupOptions.columns.splice(index, 1);
                    this.saveColumnStates();

                }
            },

            saveAllColumnsStatus(columns) {
                columns.forEach(column => {
                    this.saveColumnStatus(column);
                });
                this.saveColumnStates();
            },

            saveAllColumnsStatusOrderStatus() {
                let columns = this.$refs.empAssignmentTrip?.getColumns()
                this.orderStates = []
                columns.forEach(element => {
                    if (element.field.startsWith('timeline')) return;

                    this.orderStates.push(element.field);

                });
                this.saveColumnStates();

            },

            saveAllColumnsStatusGrooupStatus() {
                this.saveColumnStates();
            },

            createdTable() {
                let columns = this.$refs.empAssignmentTrip.getColumns()
                this.saveAllColumnsStatus(columns);
                this.resetTableTimelineVisibility();
            },

            test() {
                alert("aloooo");
            },
            
            async loadSuggestedTripsByDate(selected_date) {
                let data = {
                    "date": selected_date
                }

                await TransportService.getSuggestedTrips(data).then((result) => {
                    this.suggestedTrips = result.data;
                    this.fillSuggested();
                    this.$refs.empAssignmentTrip.refresh();

                });

            },

            async loadSuggestedTrips() {

                let data = {
                    date: this.selected_date
                }

                await TransportService.getSuggestedTrips(data).then((result) => {
                    this.suggestedTrips = result.data;
                    this.fillSuggested();
                    this.$refs.empAssignmentTrip.refresh();

                });
            },


            clearVehiclesBtn() {
                this.clearTripsChoice()
            },


            suggestVehiclesBtn() {
                this.loadSuggestedTrips();
            },


            suggestVehiclesByDataBtn() {
                this.datePickerModal.show();
            },


            // TODO
            fillSuggested() {
                this.clearTripsChoice();
                const self = this
                this.suggestedTrips.forEach(trip => {
                    let emp = self.employees.find(e => e.id == trip.driver);
                    let grid_emp = this.$refs.empAssignmentTrip.getCurrentViewRecords().find(x => x.id == trip.driver);

                    if (emp && grid_emp && !emp?.timeline?.[0]?.absence_id && emp?.timeline?.[0]?.working == true) {
                        emp.currentTripAssign.vehicle = trip.vehicle;
                        emp.currentTripAssign.driver = trip.driver;
                        self.$set(emp.currentTripAssign,'edited', true);

                        grid_emp.currentTripAssign.vehicle = trip.vehicle;
                        grid_emp.currentTripAssign.driver = trip.driver;
                        self.$set(grid_emp.currentTripAssign,'edited', true);
                    }

                    trip.passengers_obj.forEach(passenger => {
                        
                        let emp = self.employees.find(e => e.id == passenger.user_id);
                        let grid_emp = this.$refs.empAssignmentTrip.getCurrentViewRecords().find(x => x.id == passenger.user_id);

                        if (emp && grid_emp && !emp?.timeline?.[0]?.absence_id && emp?.timeline?.[0]?.working == true) {
                            if (emp.id == 42) return;
                            emp.currentTripAssign.vehicle = trip.vehicle;
                            self.$set(emp.currentTripAssign,'edited', true);

                            grid_emp.currentTripAssign.vehicle = trip.vehicle;
                            self.$set(grid_emp.currentTripAssign,'edited', true);
                        }
                    });

                });



                this.calculateCapacity();
            },

            clearTripsChoice() {
                this.clearVehicleCache();

                this.employees.forEach(employee => {
                    if (employee.currentTripAssign.vehicle) {
                        employee.currentTripAssign.vehicle = null;
                        employee.currentTripAssign.edited = true;

                    }

                });
                this.calculateCapacity();

            },


            clickHandler(args) {

                switch (args.item.id) {
                    case 'exportExcel':
                        this.$refs.empAssignmentTrip.excelExport();
                        break;
                    case 'exportPDF':
                        this.$refs.empAssignmentTrip.pdfExport();
                        break;
                    case 'print':
                        this.$refs.empAssignmentTrip.print();
                        break;
                
                    default:
                        break;
                }

            },


            selectTripByColumnName(column) {
                let timeIndex = column.split(".")[1];
                let timeValue = this.tripsTimes[timeIndex];


                this.changeDate(timeValue)
                this.loadTimelineWorkorders();
            },

            recordDoubleClick(event) {
                // this.loadTimelineWorkorders();

                if (event.column.field.startsWith('trip_timeline.') == true) {
                    this.selectTripByColumnName(event.column.field)
                }

            },

            actionFailure(args) { 
                console.log(args)
                if (args.error.message.includes("reading 'visible'")) {
                    let columns = this.$refs.empAssignmentTrip.getColumns().map(x => x.field);

                    let beforeLen = this.groupOptions.columns.length;
                    this.groupOptions.columns = this.groupOptions.columns.filter(x => {
                        if (columns.indexOf(x) !== -1) return x;
                    })

                    if (beforeLen == 0) return
                    if (beforeLen == this.groupOptions.columns) {
                        this.groupOptions.columns.pop()
                    }
                    this.$refs.empAssignmentTrip.groupSettings = this.groupOptions

                    this.saveColumnStates();
                    this.gridReady = false;
                    this.$nextTick(() => {
                        this.gridReady = true;
                    })

                }
            },


            saveSelectedVehicles() {
                let save_data = {}
                this.employees.forEach(employee => {
                    let trip = employee.trip_timeline?.[this.slectedTripsId];
                    if (!trip?.edited) return;

                    save_data[employee.id] = {'vehicle': trip.vehicle};

                })


                let path = "empAssignmentTrip.vehicles." + this.getDateTimeSeconds();
                this.$store.dispatch('updateUserCache', { path, save_data });
            },

            clearVehicleCache() {
                let save_data = {};
                let path = "empAssignmentTrip.vehicles";
                this.$store.dispatch('updateUserCache', { path, save_data });

            },

            loadCachedSelectedVehicles(employees) {
                this.$store.dispatch('loadUserCache');
                if (this.userCache?.empAssignmentTrip?.vehicles?.[this.getDateTimeSeconds()] == undefined) {
                    if (this.selected_date >= new Date().toISOString().slice(0,10)) this.loadSuggestedTrips();
                    return;
                }
                let saved_data = this.userCache.empAssignmentTrip.vehicles[this.getDateTimeSeconds()];

                console.log(this.slectedTripsId);
                employees.forEach(employee => {

                    if (!saved_data[employee.id]) return;

                    if (employee.trip_timeline?.[this.slectedTripsId]) {
                        employee.trip_timeline[this.slectedTripsId].vehicle = saved_data[employee.id].vehicle;
                        employee.trip_timeline[this.slectedTripsId].edited = true;
                    }
                })
                console.log("Loaded cached trips");
                
                this.calculateCapacity();

            },

            showAssignModal() {
                this.$refs.asignTrip.load();
                this.assignTripModal.show();
            },

            
            loadLocalStorage() {
                this.$store.dispatch('loadUserCache');

                // if (this.userSettings?.empAssignmentTrip?.selectedDays?.daysBefore !== undefined) this.daysBefore = parseInt(this.userSettings.empAssignmentTrip.selectedDays.daysBefore);
                // if (this.userSettings?.empAssignmentTrip?.selectedDays?.daysAfter !== undefined) this.daysAfter = parseInt(this.userSettings.empAssignmentTrip.selectedDays.daysAfter);
                if (this.userSettings?.empAssignmentTrip?.grid?.width !== undefined) this.widthStates = this.userSettings.empAssignmentTrip.grid.width;
                if (this.userSettings?.empAssignmentTrip?.grid?.visible !== undefined) this.visibleStates = this.userSettings.empAssignmentTrip.grid.visible;
                if (this.userSettings?.empAssignmentTrip?.grid?.order !== undefined) this.orderStates = this.userSettings.empAssignmentTrip.grid.order;
                if (this.userSettings?.empAssignmentTrip?.grid?.group !== undefined) this.groupOptions.columns = this.userSettings.empAssignmentTrip.grid.group;
                if (this.userSettings?.empAssignmentTrip?.currentSelect?.date !== undefined) this.currentSelectDate = this.userSettings.empAssignmentTrip.currentSelect?.date;
                if (this.userSettings?.empAssignmentTrip?.currentSelect?.data !== undefined) this.currentSelectData = this.userSettings.empAssignmentTrip.currentSelect?.data; 
                
            },

            saveSelectedDays() {
                // let path = "empAssignmentTrip.selectedDays"
                // let save_data = {

                // }
                // this.$store.dispatch('updateUserSettings', { path, save_data });

            },

            saveColumnStates() {

                let path = "empAssignmentTrip.grid"
                let save_data = {
                    width: this.widthStates,   
                    visible: this.visibleStates,   
                    order: this.orderStates,
                    group: this.groupOptions.columns
                }
                this.$store.dispatch('updateUserSettings', { path, save_data });
            },

            dataBound() {
                this.updateTableCellData();
                this.autoFitTimelineColumns();
            },

            updateTableCellData() {
                console.log('UPDATING CELLS');
                
                const self = this;
                let data = this.$refs.empAssignmentTrip.getCurrentViewRecords();
                data.forEach(emp => {
                    let emp_data = self.employees.find(x => x.id === emp.id)
                    if (!emp_data) return;
                    try {
                        emp.currentTripAssign.vehicle = emp_data.currentTripAssign.vehicle;
                        emp.currentTripAssign.driver = emp_data.currentTripAssign.driver;
                        emp.currentTripAssign.edited = emp_data.currentTripAssign.edited;
                    } catch (e) {
                        console.log(e);
                    }
                })
                
            },

            autoFitTimelineColumns() {
                let columns = this.$refs.empAssignmentTrip.getColumns();

                columns.forEach(column => {
                    if (column.field.startsWith('timeline') || column.field.startsWith('selected_vehicle_id'))
                    this.$refs.empAssignmentTrip.autoFitColumns([column.field]);
                });

            },

            enableEditMode() {
                this.edited = true;
                this.$store.dispatch('enableClosePrompt', {message: "Niste potvrdili izmenu"});
            },

            disableEditMode() {
                this.edited = false;
                
                this.$store.dispatch('disableClosePrompt');
                this.clearEdited();
            },

            clearEdited() {
                this.employees.forEach(employee => {
                    if (employee?.currentTripAssign?.edited)
                        employee.currentTripAssign.edited = false;
                });
                this.$refs.empAssignmentTrip.getCurrentViewRecords().forEach(employee => {
                    if (employee?.currentTripAssign?.edited)
                        employee.currentTripAssign.edited = false;
                });

            },

            // saveStateToLocal() {
            //     let path = "empAssignmentTrip.currentSelect"

            //     let data = {};

            //     console.log(this.employees);
            //     this.employees.forEach(employee => {
            //         if (!employee.selected_vehicle_id) return;
            //         data[employee.id] = employee.selected_vehicle_id;
            //     });


            //     let save_data = {
            //         data: data,   
            //         date: new Date().toISOString().slice(0,10),
            //     }
            //     this.$store.dispatch('updateUserSettings', { path, save_data });
            // },

            async reorderColuns() {
                await this.$refs.empAssignmentTrip.reorderColumns(this.orderStates, "");
            },

            hideModal() {
                this.clearVehicleCache();
                this.assignTripModal.hide();
                this.disableEditMode();
            },

            loadProp() {
                let initDate = this.$route.params.initalSelectedDate;
                if (initDate) this.selected_date = initDate;
            },

        },

        created() {
            this.loadProp();
            this.loadLocalStorage();
            this.load_data();
        },

        mounted() {
            this.assignTripModal = new Modal(document.getElementById('assignTripModal'));
            this.datePickerModal = new Modal(document.getElementById('datePickerModal'));
        },

    }
    
</script>

<style>

    .trip-assign-table .e-gridcontent > .e-content {
        height: calc(100vh - 250px) !important;
    }


    .trip-assign-table table .no-work-order {
        background: #777777;
    }
    .trip-assign-table table tr:hover .no-work-order {
        background: #666666 !important;
    }
    .trip-assign-table table .absence-vacation-day {
        background: #0dff00;
    }
    .trip-assign-table table tr:hover .absence-vacation-day {
        background: #0cdd01 !important;
    }


    .trip-assign-table table .absence-sickness-day {
        background: #ff6449;
    }
    .trip-assign-table table tr:hover .absence-sickness-day {
        background: #e9583e !important;
    }

    .trip-assign-table table .absence-religious-day {
        background: #ff6449;
    }
    .trip-assign-table table tr:hover .absence-religious-day {
        background: #e9583e !important;
    }

    .trip-assign-table table .absence-other-day {
        background: #ffb649;
    }
    .trip-assign-table table tr:hover .absence-other-day {
        background: #e9a53e !important;
    }

    .trip-assign-table table .non-working-day {
        background: #777777;
        color: #777777 !important;
    }
    .trip-assign-table table tr:hover .non-working-day {
        background: #666666 !important;
        color: #666666 !important;
    }

    .trip-assign-table table tr td.time-col-0 {
        border-left: 3px solid #e0e0e0 !important;
    }

    .trip-assign-table.e-grid .e-gridheader { 
        position: -webkit-sticky; 
        position: sticky; 
        top: 0px; /* The height of top nav menu. */ 
        /* border-bottom: 1px solid #222; */
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.142);
        z-index: 1; 
    } 

    .trip-assign-table .e-groupdroparea .e-grouptext {
        width: 120px;
    }

    .trip-assign-table .e-table .e-groupcaption {        
        font-size: 14px;
        font-weight: bold;
    }
</style>

<style scoped>

.container {
    min-height: 74vh;
}

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    padding: 16px 30px;
    margin: 0 0 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .white-btn {
    height: 100%;    
    background: #fff;
    width: 100%;
}

.table-title .btn.btn-w:hover, .table-title .btn.btn-w:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}

.table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 4vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 100px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.time-close {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    background: #fff;
}

.table th.absence-day {

    background: orange;
}

.table th.no-work-order {
    background: gray;
}

.vehicle-form-footer {
    width: 100%;
    height: 70px;
    padding-top: 10px;
    background: rgba(255, 255, 255, 0.83);
    position: sticky;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    box-shadow: rgba(0, 0, 0, 0.14) 0 -5px 5px;
}

.vehicle-form-footer .container {
    min-height: 60px !important;
}


</style>